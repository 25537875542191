import { LatLong, LLA } from './coordinates.mjs'

export enum realmEnum 
{
    BI = "BI",
    FRALPS = "Fr Alps"
}

export class Realm
{
    readonly bottom_left_corner : LatLong;
    readonly top_right_corner: LatLong;
    readonly default_location: LLA;
    readonly description: string;
    readonly height_multiplier: number;
    readonly database_filename:string;

    static current: realmEnum = realmEnum.BI;

    constructor(bottom_left_corner : LatLong, top_right_corner: LatLong, default_location: LLA,
                    height_multiplier: number, description: string, database_filename: string)
    {
        this.bottom_left_corner = bottom_left_corner;
        this.top_right_corner = top_right_corner;
        this.default_location = default_location;
        this.height_multiplier = height_multiplier;
        this.description = description;
        this.database_filename = database_filename;
    }

    containsLatLong(lla : LatLong) : boolean
    {
        const valid = this.bottom_left_corner.latitude <= lla.latitude && lla.latitude <= this.top_right_corner.latitude &&
                      this.bottom_left_corner.longitude <= lla.longitude && lla.longitude <= this.top_right_corner.longitude;

        return valid;
    }

    static validLatLong(lla : LatLong) : boolean
    {
        const matches = [...this.realms.values()].filter(realm => realm.containsLatLong(lla));
        return matches.length == 1;
    }
    
    static setGlobalRealmfromLLA(lla : LatLong) : void
    {
        const rm = this.getRealmFromLLA(lla);
        if (rm)
            Realm.current = rm;
        else
            console.assert(false, "setGlobalRealm fail");
    }
    static getRealmFromLLA(lla: LatLong) : realmEnum | null
    {
        const matches = [...this.realms.entries()].filter(kv => kv[1].containsLatLong(lla));

        if (matches.length)
            return matches[0][0];

        console.assert(false, "getRealmFromLLA fail");
        return null;
    }
    static getCurrent() : Realm | undefined
    {
        const realm = this.realms.get(this.current);
        if (realm)
            return realm;

        console.assert(false, "getCurrent fail");
    }
    static getCurrentHeightMultiplier() : number
    {
        const realm = this.realms.get(this.current);
        if (realm)
            return realm.height_multiplier;

        console.assert(false, "getCurrentHeightMultiplier");
        return 1;
    }
    static getRealmDatabaseFilename(rm: realmEnum)
    {
        const realm = this.realms.get(rm);
        const _return = realm ? realm.database_filename : "";
        
        console.assert(realm, "getRealmDatabaseFilename");

        return _return;
    }

     static realms = new Map([
        [realmEnum.BI, new Realm(new LatLong(49, -12), new LatLong(60, 1),new LLA(54.482, -3.219, 0.900),
                        1.0, "Britain and Ireland", `./resources/DoBIH_v17_4.json`)],
        [realmEnum.FRALPS, new Realm(new LatLong(44, 5), new LatLong(48, 8), new LLA(45.729722, 6.351111, 2.062),
                        0.25, "French Alps", `./resources/DBoFRAlps.json`)]
      ]);
}


  