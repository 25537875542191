

import { g_scene_camera, zoomCamera, getCameraPosition, remakeFog, remakeMaterials } from "./camera.mjs";
import { Bearing } from "./coordinates.mjs";
import { translateY } from "./events.mjs";
import { byId, css_show, css_hide, css_is_shown, set_slider, slider_params, init_slider } from "./html-util.mjs";
import { getSunBearing, moveLights } from "./lights.mjs";
import { VisualisationType, g_settings } from './settings.mjs'


/// Updates most of the controls in the display
export function positionMessage(bearing: Bearing, altitude_metres: number, camera_zoom: number)
{
    const set_ctl = (name: string, text: string) =>
            {
                const display = byId(name);
                if (display) display.innerText = text;
            }    
    
    set_ctl('altitude-display',    `✈ ${altitude_metres.toFixed(0)}m`);
    set_ctl('zoom-display',        `📷 ${camera_zoom.toFixed(1)}`);
    set_ctl('bearing-display',     `🧭 ${bearing.toString()}`);

    set_slider('altitude-slider', altitude_metres, Math.log10,       (x: number) => x.toFixed(0));
    set_slider('zoom-slider',     camera_zoom,     (x: number) => x, (x: number) => x.toFixed(1));
    set_slider('fog-slider',      g_settings.fog,  Math.log,         (x: number) => x.toFixed(1));

    set_slider('visualisation-fog',  g_settings.fog,  Math.log,      (x: number) => x.toFixed(1));

    updateMaterialRadios();
}

export function updateMaterialRadios()
{
    material_radios.forEach(mr =>
    {
        const radio = byId(mr.id);
        if (radio)
            (radio as HTMLInputElement).checked = g_settings.material_type == mr.material;
    });
}

let timeout_id :ReturnType<typeof setTimeout>;

export function toast(_string : string)
{
    const toast_element = byId('toast');
    if (toast_element)
    {
        if (css_is_shown(toast_element))
        {
            toast_element.innerHTML = _string + "<BR>" + toast_element.innerHTML;
            clearTimeout(timeout_id);
        }
        else
        {
            toast_element.innerHTML = _string;
            css_show(toast_element);
        }

        timeout_id = setTimeout(() => { css_hide(toast_element) }, 5000);
    }
}



/** hide the OSM attribution after 8 seconds */
export function hideAttributions()
{
    const div = byId("osm-attribution") as HTMLDivElement;
    if (div)
    {
         setTimeout(() => { css_hide(div) }, 8000);
    }
}

function sunSlider(x: number)
{
    const sun_bearing = getSunBearing();
    if (sun_bearing)
        {
            moveLights(sun_bearing.toDegrees() - x);
        }
}

function altitudeSlider(x: number)
{
    const altitude = Math.pow(10, x);

    const position = getCameraPosition(g_scene_camera.camera);

    const current_altitude_metres = position.lla.altitude * 1000;

    translateY(altitude - current_altitude_metres)
}

function zoomSlider(x: number)
{
    zoomCamera(g_scene_camera.camera, x);
}

function fogSlider(x: number)
{
    g_settings.fog = Math.exp(x);

    remakeFog();
}

const display_sliders : slider_params[] = 
[
    { n: 'sun-slider',      min: 45,  max: 300,  step:10,  on_inp:sunSlider },
    { n: 'altitude-slider', min: 0,   max: 4,    step: 0.1, on_inp:altitudeSlider },
    { n: 'zoom-slider',     min: 0.5, max: 20.0, step: 0.5, on_inp:zoomSlider },
    { n: 'fog-slider',      min: 0.1, max: 5,    step: 0.5, on_inp:fogSlider }
];

const material_radios =
[
    { id: "header-displays-popup-3D",   material: VisualisationType.Mat3D},
    { id: "header-displays-popup-phys", material: VisualisationType.MatPhys},
    { id: "header-displays-popup-pano", material: VisualisationType.MatPano},
    { id: "visualisation-3D",           material: VisualisationType.Mat3D},
    { id: "visualisation-phys",         material: VisualisationType.MatPhys},
    { id: "visualisation-pano",         material: VisualisationType.MatPano},
];


export function initPopups()
{
    const header_displays = byId('header-displays')
    const header_displays_popup = byId('header-displays-popup')
    if (header_displays && header_displays_popup)
    {
        header_displays.addEventListener('click', () => 
        {
            if (css_is_shown(header_displays_popup))
            {
                css_hide(header_displays_popup)
            }
            else
            {
                css_show(header_displays_popup)
            }
        });

        display_sliders.forEach((slider) => init_slider(slider));

        document.querySelectorAll("[name=header-displays-popup-visualisations]").forEach(
            radio => radio.addEventListener('change', () => 
            {
                const r = material_radios.find(rr => rr.id == radio.id);
                if (r)
                {
                    g_settings.material_type = r.material;
                    remakeMaterials();
                }
            }));
    }
}