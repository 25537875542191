

import 
{
    Scene,
    Mesh,
    ShaderMaterial,
    DirectionalLight,
    DirectionalLightHelper,
    SphereGeometry,
    MeshBasicMaterial,
    BoxHelper,
    Vector3,
    Object3D
} from "three"

import
{
    WebGLRenderer,
    PerspectiveCamera,
    Fog,
    ColorManagement,
    MathUtils
}
from 'three'

import { g_scene_camera, sg_sun_distance, sg_sun_diameter, getCameraBearing, getCameraPosition } from "./camera.mjs";

import { LLA, ECEF, Bearing, localElevationAngle, localAzimuthAngle } from './coordinates.mjs'

import { gEventHandler, clamp } from './events.mjs'

import { g_settings } from './settings.mjs'

import { set_slider } from "./html-util.mjs";

import { Sky } from 'three/addons/objects/Sky.js';
import { GUI } from 'three/addons/libs/lil-gui.module.min.js';



/** Make the lights */
export function makeLights(scene: Scene)
{
    ColorManagement.enabled = true;

    const sun_init_longitude = -22.5;
    const latlong = new LLA(tropic, sun_init_longitude, sg_sun_distance);   // Earth orbits the sun at an average of 149.6 million km 149, 600, 000

    const ecef = ECEF.fromLLA(latlong);

    const intensity = 1; // Math.PI;
    const dirLight = new DirectionalLight( 0xffffff, intensity );
    dirLight.position.set(ecef.x, ecef.y, ecef.z);
    dirLight.name = 'sun';
    scene.add( dirLight );

    dirLight.castShadow = true;

    const helpers = false;
    if (helpers)
    {
        const dirLightHelper = new DirectionalLightHelper( dirLight, 10 );
        scene.add( dirLightHelper );
    }

/*     const geometry = new SphereGeometry( sg_sun_diameter / 2, 32, 16 ); // the Sun has a diameter of 1,391,400 km 
    const material = new MeshBasicMaterial( { color: 0xffff00 } ); 
    const sphere = new Mesh( geometry, material ); 
    sphere.name = 'sun';

    sphere.position.set(ecef.x, ecef.y, ecef.z);

    scene.add( sphere ); */
}

export function makeFog(scene: Scene)
{
    let fog_distance = g_settings.fog;
    if (typeof fog_distance === "number" && !Number.isNaN(fog_distance))
    {  
        fog_distance = clamp(fog_distance, 1, 300);
        scene.fog = new Fog(  0xcccccc /* 0xefd1b5 */, fog_distance * 0.75, fog_distance * 1.25 );
        
    }
}

const tropic = 23.5;

/// compute the sun's bearing and display it
function showSunPos(elevation: number, azimuth: number)
{
    const sun_display = document.getElementById("sun-display") as HTMLDivElement;

    const bearing = getSunBearing();

    if (bearing)
    {
        if (sun_display)
            sun_display.innerHTML = `🔅 ${bearing.toCardinal()}`;

        set_slider('sun-slider', bearing.toDegrees(), (x) => x, (x) => new Bearing({ d: x }).toCardinal());
    }
}

/// comput the bearing of the sun
export function getSunBearing()
{
    const dirLight = g_scene_camera.scene.getObjectByName('sun');
    if (dirLight)
    {
        const position = dirLight.position.clone();
        const ecef = new ECEF(position.x, position.y, position.z);
        const lla = LLA.fromECEF(ecef);

        const lon = lla.longitude;

        const bearing = new Bearing({ d: 180 - lon });
        return bearing;
    }
    else
    {
        return null;
    }
}

/** move the position of the sun north/south and east/west */
export function moveLights(deltaX : number)
{
    const dirLight = g_scene_camera.scene.getObjectByName('sun');
    if (dirLight)
    {
        const current_position = dirLight.position.clone();
        const current_ecef = new ECEF(current_position.x, current_position.y, current_position.z);
        const current_lla = LLA.fromECEF(current_ecef);

        let lon = current_lla.longitude + deltaX * 1
        lon = clamp(lon, -120, +150); 
        
        const new_lla = new LLA(tropic, lon, current_lla.altitude);
        const new_ecef = ECEF.fromLLA(new_lla);
        dirLight.position.set(new_ecef.x, new_ecef.y, new_ecef.z);
        
        updateSky();
    }
}

let sky: Mesh;
let gui: GUI;


function initSky(scene: Scene, renderer : WebGLRenderer)
{
    // Add Sky
    sky = new Sky() as Mesh;
    sky.scale.setScalar(450000);
    scene.add(sky);

    /// GUI
    const effectController = 
    {
        turbidity: 0,   // originally 10
        rayleigh: 0.625, // originally 3,
        mieCoefficient: 0.005,
        mieDirectionalG: 0.7,
        elevation: 2,
        azimuth: 180,
        exposure: renderer.toneMappingExposure
    };

    function guiChanged()
    {
        const material = sky.material as ShaderMaterial;
        const uniforms = material.uniforms;
        uniforms['turbidity'].value = effectController.turbidity;
        uniforms['rayleigh'].value = effectController.rayleigh;
        uniforms['mieCoefficient'].value = effectController.mieCoefficient;
        uniforms['mieDirectionalG'].value = effectController.mieDirectionalG;
        
        const phi = MathUtils.degToRad(90 - effectController.elevation);
        const theta = MathUtils.degToRad(effectController.azimuth);
        
        const sun = new Vector3();
        sun.setFromSphericalCoords(1, phi, theta);

        uniforms['sunPosition'].value.copy(sun);

        renderer.toneMappingExposure = effectController.exposure;

        gEventHandler.setDirty();
    }

    gui = new GUI();

    gui.add(effectController, 'turbidity', 0.0, 20.0, 0.1).onChange(guiChanged);
    gui.add(effectController, 'rayleigh', 0.0, 4, 0.001).onChange(guiChanged);
    gui.add(effectController, 'mieCoefficient', 0.0, 0.1, 0.001).onChange(guiChanged);
    gui.add(effectController, 'mieDirectionalG', 0.0, 1, 0.001).onChange(guiChanged);
    gui.add(effectController, 'elevation', 0, 90, 0.1).onChange(guiChanged);
    gui.add(effectController, 'azimuth', - 180, 180, 0.1).onChange(guiChanged);
    gui.add(effectController, 'exposure', 0, 1, 0.0001).onChange(guiChanged);

    gui.hide();

    guiChanged();
}


/**
 *  Generate a scene and camera for the background of the image
 */
export function generateBackgroundScene(renderer : WebGLRenderer)
{
  renderer.autoClear = false;

  const backgroundCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 100, 2000000 );
  backgroundCamera.position.set( 0, 100, 2000 );
  
  const backgroundScene = new Scene();

  initSky(backgroundScene, renderer);

  g_scene_camera.background_scene = backgroundScene;
  g_scene_camera.background_camera = backgroundCamera;
}  

export function updateSky()
{
    const dirLight = g_scene_camera.scene.getObjectByName('sun');
    if (dirLight)
    {
        const sun_pos = dirLight.position.clone();

        const sun_ecef = new ECEF(sun_pos.x, sun_pos.y, sun_pos.z);   
        const sun_lla = LLA.fromECEF(sun_ecef);
        const lon = sun_lla.longitude;

        const sun_bearing = new Bearing({d:180 - lon});

        const camera_position = getCameraPosition(g_scene_camera.camera);
        const camera_bearing = getCameraBearing(g_scene_camera.camera, camera_position).toDegrees();

        const date = new Date('21 jun 2024');
        const sun_hour = sun_bearing.toDegrees() / 15 + 2;
        const sun_minute = 60 * (sun_hour - Math.trunc(sun_hour))
        date.setHours(Math.trunc(sun_hour), sun_minute);

        const sun_elevation = localElevationAngle(date, camera_position.lla.latitude, camera_position.lla.longitude);
        const sun_azimuth = localAzimuthAngle(date, camera_position.lla.latitude, camera_position.lla.longitude);
        const sun_azimuth_180 = sun_azimuth - 180;
        const sun_azimuth_rel = camera_bearing - sun_azimuth_180;

        // console.debug(`sun_hour ${sun_hour.toFixed(1)} localElevationAngle ${sun_elevation.toFixed(1)} localAzimuthAngle ${sun_azimuth.toFixed(1)} rel Azimuth ${sun_azimuth_rel.toFixed(1)} date ${date.toLocaleTimeString()}`)

        gui.controllers[4].setValue(sun_elevation); 
        gui.controllers[5].setValue(sun_azimuth_rel); 
           
        showSunPos(sun_elevation, sun_azimuth);

    }
}
 