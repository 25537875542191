
import { PerspectiveCamera, REVISION } from 'three';
import { DEM } from './dem.mjs'
import { findBestHillForXY } from './labeller.mjs';
import { HillsList, hill  }from './dobih.mjs'
import {  LatLong } from './coordinates.mjs'
import { g_scene_camera, getCameraPosition } from './camera.mjs'
import { startGetGPS, gpsPosition } from './gps.mjs'
import { installationInfo, installationInfofromServiceWorker, installationUninstall, installationUpdate } from './installation.mjs'
import { viewerPositionDirectionZoom } from './viewposition.mjs'
import { Realm } from './realm.mjs';
import { hideAttributions } from "./popups.mjs";
import { css_show, css_hide, css_is_shown } from "./html-util.mjs";

export function initDialogs()
{
    const settings_dialog = document.getElementById("settings-dialog") as HTMLDivElement;
    if (settings_dialog)
    {
        const tabs = [...settings_dialog.querySelectorAll(".tabBlock-tab")] as HTMLDivElement[];
        const panes = [...settings_dialog.querySelectorAll(".tabBlock-pane")] as HTMLDivElement[];
        
        const switchTab = ((to_tab : HTMLDivElement) =>
                        {
                            tabs.forEach((tab) => tab.classList.remove('is-active'));
                            to_tab.classList.add('is-active');
        
                            const index = tabs.indexOf(to_tab);
        
                            panes.forEach((pane, i) => pane.style.display = i == index ? 'block' : 'none');
                        });
        tabs
        .forEach((tab) => 
            { 
            tab.addEventListener('click', (event : MouseEvent) => switchTab(event.target as HTMLDivElement));
            if (tab.classList.contains('is-active')) switchTab(tab);
            });
            
        initDialogButtons(settings_dialog);
        
        const help_pane_idx = panes.findIndex(pane => pane.querySelector('#help-grid') != null)
        const help_tab = 0 <= help_pane_idx ? tabs[help_pane_idx] : null;

        initHelp(settings_dialog, help_tab);
    }

    hideAttributions();
}

/** assign click functions to buttons on the dialog */
function initDialogButtons(settings_dialog: HTMLDivElement)
{
    const addClick = (parent: HTMLDivElement, id: string, func: (event: Event) => void) => 
    {
        const button = parent.querySelector(`#${id}`) as HTMLButtonElement;
        button.addEventListener('click', func)
    }

    const hill_info_popup = document.getElementById('hill-info') as HTMLDivElement;
    const goto_gps_location = settings_dialog.querySelector(`#goto-gps-location`) as HTMLInputElement;

    addClick(hill_info_popup, 'hill-info-goto', () => hill_info_goto());
    hill_info_popup.addEventListener('click', () =>css_hide(hill_info_popup));  // hide if background click

    addClick(settings_dialog, 'gotohill-go', () => gotohill_goto(settings_dialog));
    addClick(settings_dialog, 'settings-dialog-close', () => css_hide(settings_dialog));
    addClick(settings_dialog, 'goto-gps-get', () => startGetGPS(goto_gps_location));
    addClick(settings_dialog, 'goto-gps-go', () => GPS_goto(settings_dialog));

    addClick(settings_dialog, 'view-copy', () => viewCopy());

    addClick(settings_dialog, 'installation-uninstall', () => installationUninstall());
    addClick(settings_dialog, 'installation-update', () => installationUpdate());
}

export function settings_dialog_show(tab_name? : string)
{
    const settings_dialog = document.getElementById("settings-dialog") as HTMLDivElement;

    installationGetInfo();

    computeViewerPositionDirectionZoom();

    // data checkboxes
    set_checkboxes_from_srtms(settings_dialog);

    // init realms
    set_realms(settings_dialog);

    makeDataObjectforHillsList();

    // versions
    const three_js_version = settings_dialog.querySelector(`#THREE-JS-VERSION`) as HTMLDivElement;
    three_js_version.innerText = REVISION;

    const hardware_concurrency = settings_dialog.querySelector('#NAV-HARDWARECONCURRENCY') as HTMLSpanElement;
    hardware_concurrency.innerHTML = `${navigator.hardwareConcurrency}`;
    
    if (tab_name)
    {
        const tabs = [...settings_dialog.querySelectorAll(".tabBlock-tab")] as HTMLDivElement[];
        const go_to_tab = tabs.filter(tab => tab.innerText == tab_name)
        if (go_to_tab.length)
            go_to_tab[0].dispatchEvent(new Event('click'));
    }
    
    css_show(settings_dialog);
}

/** tab dialog ------------------------------------------- */  


function makeDemTileCheckboxes(settings_dialog : HTMLDivElement)
{
    const realm = Realm.getCurrent();
    const latitudes : number[] = [];
    const longitudes : number[] = [];

    if (realm)
    {
        for (let lat = realm.bottom_left_corner.latitude; lat < realm.top_right_corner.latitude; lat++)
            latitudes.push(lat);
        for (let long = realm.bottom_left_corner.longitude; long < realm.top_right_corner.longitude; long++)
            longitudes.push(long);
    }

    const nrows = latitudes.length;
    const ncols = longitudes.length;
    const checkboxes_grid = settings_dialog.querySelector('#checkboxes-grid') as HTMLDivElement;

    checkboxes_grid.replaceChildren(); // delete all children
    
    const setGridRowColAppend = (element: HTMLElement, row: number, col: number) => 
                        {   element.style.gridColumn = (col + 1).toString();
                            element.style.gridRow = (row + 1).toString(); 
                            element.style.width = '16px';
                            element.style.height = '16px';
                            checkboxes_grid.appendChild(element);
                        }

   
    checkboxes_grid.style.gridTemplateRows = (nrows + 1).toString();
    checkboxes_grid.style.gridTemplateColumns = (ncols + 1).toString();

    for (let row = 0; row < nrows; row++)
    {
        const latitude = latitudes[nrows - row - 1];

        const label = document.createElement('div');
        label.innerText = latitude.toString() + 'N';
        setGridRowColAppend(label, row + 1, 0);

        for (let col = 0; col < ncols; col++)
        {
            const longitude = longitudes[col];
            const latlong = new LatLong(latitude, longitude);

            if (row == 0)
            {
                const label = document.createElement('div');
                const lon_c  = 0 < longitude ? 'E' : 'W';
                const lon_mpy = 0 < longitude ? 1 : -1;

                label.innerText = (longitude * lon_mpy).toString() +  lon_c;
                label.style.textAlign = 'center';
                setGridRowColAppend(label, 0, col + 1);
            }

            const control = document.createElement('div');
            control.id = latlong.toLatLongString();
            
            setGridRowColAppend(control, row + 1, col + 1);
        }
    }
}

/** initialise the dialog from the strms that are loatded */
function set_checkboxes_from_srtms(settings_dialog : HTMLDivElement)
{
    makeDemTileCheckboxes(settings_dialog);

    const checkboxes_grid = settings_dialog.querySelector('#checkboxes-grid') as HTMLDivElement;
    
    const dem_names_resolutions = DEM.dems_loaded();

    const all_checkboxes = [...checkboxes_grid.querySelectorAll("div")] as HTMLDivElement[];
    all_checkboxes.forEach(control => 
                                { if (control.id)
                                    {
                                        let colour : string;
                                        const loaded = dem_names_resolutions.filter(name_reso => name_reso.name == control.id);
                                        if (0 < loaded.length)
                                        {
                                            colour = 'DarkGreen'
                                        }
                                        else
                                        {
                                            colour = 'White';
                                        }

                                        control.style.backgroundColor = colour;
                                    }  
                                });
}


let dialogs_timeout_id : ReturnType<typeof setTimeout>;

/** show the information pop-up */
export function showHillInfoPopup(event_x : number, event_y : number,  camera: PerspectiveCamera)
{
    const hill = findBestHillForXY(event_x, event_y, camera);
    if (hill)
        showHillInfoPopupGivenHill(hill);
}

function hill_info_goto()
{
    const hill_info_popup = document.getElementById('hill-info') as HTMLDivElement;
    if (hill_info_popup && hill_info_popup.dataset && hill_info_popup.dataset.hill_id)
    {
        const hill_id = parseInt(hill_info_popup.dataset.hill_id);
        const hill = HillsList.current().hills.find(hill => hill.number == hill_id);

        if (hill)
        {
            const location = viewerPositionDirectionZoom.fromLLA(hill.LLA);
            location.goto();
        }
    }
}



export function showHillInfoPopupGivenHill(hill: hill)
{
    if (hill)
    {
        const hill_info_popup = document.getElementById('hill-info') as HTMLDivElement;
        if (css_is_shown(hill_info_popup))
        {
            clearTimeout(dialogs_timeout_id);
        }
        else
        {
            css_show(hill_info_popup);
        }
        
        const position = getCameraPosition(g_scene_camera.camera);

        const bearing = position.lla.ComputeBearing(hill.LLA);
        const distance = position.lla.ComputeDistance(hill.LLA);
        const location = HillsList.current().locations[hill.location];

        const set_ctl = (control : string, string : string) => { const div = document.getElementById("hill-info-" + control); 
                                                                 if (div) div.innerText = string
                                                                };

        set_ctl("height",     `${hill.metres}m`)
        set_ctl("distance",   `${distance.toFixed(1)}km`);
        set_ctl("bearing",    `${bearing.toString()}`);
        set_ctl('heading',    `${hill.name}`);
        set_ctl('location',   `${location}`);

        hill_info_popup.dataset.hill_id = hill.number.toString();
    
        dialogs_timeout_id = setTimeout(() => { css_hide(hill_info_popup);  }, 5000);
    }
}

/** go to a named hill */
function gotohill_goto(settings_dialog : HTMLDivElement)
{
    const gotohill_search =  settings_dialog.querySelector('#gotohill-search') as HTMLInputElement;

    // TODO better matching for no popup
    const search_value =  gotohill_search.value.trim().toUpperCase();

    const hill = HillsList.current().hills.find(hill => hill.name.toUpperCase() == search_value);
    if (hill)
    {
        const location = viewerPositionDirectionZoom.fromLLA(hill.LLA);
        location.goto();
        
        css_hide(settings_dialog);
    }
}

/** go to gps when position is known */
function GPS_goto(settings_dialog : HTMLDivElement)
{
    if (gpsPosition.known && gpsPosition.position != null)
    {
        const location = viewerPositionDirectionZoom.fromLLA(gpsPosition.position);
        location.goto();
        
        css_hide(settings_dialog);
    }
}

const helps = [

    { c:"Left mouse",                   t:"Tap",                          a:""},
    { c:"Single click on a hill/label", t:"Tap on a hill/label",          a:"Show info on the hill"},
        
    { c:"Left mouse drag",              t:"One touch",                    a:""},
    { c:"Left/right",                   t:"Left/right",                   a:"Pan"},
    { c:"Drag up/down",                 t:"Up/down",                      a:"Move up/down "},
        
    { c:"Zoom 📷",                      t:"Zoom 📷",                     a:""},
    { c:"Mouse wheel",                  t:"Pinch in/out",                 a:"Camera zoom 0.5 to 20"},

    { c:"Shift + left mouse",           t:"Two touch (fingers together)", a:""},
    { c:"Drag up/down",                 t:"Up/down",                      a:"Move forward/back "},
    { c:"Drag left/right",              t:"Left/right",                   a:"Move left/right"},

    { c:"Ctrl + left mouse",            t:"Two touch (fingers apart)",    a:""},
    { c:"Drag up/down",                 t:"Look up/down",                 a:"Look up/downback "},

    { c:"Alt + left mouse ",            t:"Triple touch",                 a:""},
    { c:"Left/right",                   t:"Drag left/right",              a:"Move sun north/south"}
]

function initHelp(settings_dialog :HTMLDivElement, help_tab: HTMLDivElement | null) 
{
    const pointer_coarse = window.matchMedia("(pointer: coarse)");
    const is_touch = pointer_coarse.matches;

    const help_grid =  settings_dialog.querySelector('#help-grid') as HTMLDivElement;
    const add = (_string: string, sub_head:boolean) => 
    { 
        const div = document.createElement('div'); 
        if (sub_head)
        {
            div.classList.add("help-subhead");
        }

        div.innerHTML = _string; 

        help_grid.appendChild(div)
    };

    helps.forEach(help => 
        {
            const sub_head = help.a.length == 0;

            if (is_touch)
                add(help.t, sub_head);
            else
                add(help.c, sub_head);

            add(help.a, false);
        })
    
    if (!is_touch && help_tab)
        help_tab.innerText = "Mouse";
}

/// collect installation info from the service worker and display
function installationGetInfo()
{
    const div0 = document.getElementById('installation-info0') as HTMLInputElement;
    const div1 = document.getElementById('installation-info1') as HTMLInputElement;
    const div2 = document.getElementById('installation-info2') as HTMLInputElement;
    const div3 = document.getElementById('installation-info3') as HTMLInputElement;
    const div5 = document.getElementById('installation-info5') as HTMLInputElement;
    const div6 = document.getElementById('installation-info6') as HTMLInputElement;

    const report_installation_info = (info: installationInfo) => 
    { 
        div0.value     = info.message ?? "";
        div1.value = (info.cached ?? 0).toString();
        div3.value = info.timestamp || "";

    }
    const report_memory_estimate = (info: string) => 
    { 
        div2.value = info;
    }                

    installationInfofromServiceWorker(report_installation_info, report_memory_estimate);

    const body_style = getComputedStyle(document.body)
    div5.value = body_style.getPropertyValue('--timestamp');
    div6.value = "2024-08-25T21:18:33";
}

function makeLocationURL()
{
    const viewer_location = viewerPositionDirectionZoom.fromCamera(g_scene_camera.camera);
    const url_parameter = viewer_location.toURLParameter();

    const url = `${location.protocol}//${location.host}${location.pathname}`;
    return `${url}${url_parameter}`;
}

/** set the control that shows the URL */
function computeViewerPositionDirectionZoom()
{
    const _string = makeLocationURL();

    const anchor = document.getElementById("view-location") as HTMLAnchorElement;
    if (anchor)
    {
        anchor.href = _string;
        anchor.innerText = _string;
    }
}

/// Copy url and image to clipboard
function viewCopy()
{
    const _string = makeLocationURL();
    
    navigator.clipboard.writeText(_string).then(
        (s) => { console.debug("clipboard success", s); },
        (f) => { console.debug("clipboard failure", f); }
      );
}

function set_realms(settings_dialog : HTMLDivElement)
{
    const realm_list = settings_dialog.querySelector("#realm-list");
    if (realm_list)
    {
        realm_list.replaceChildren(); // delete all children

        Realm.realms.forEach((realm, rm) =>
        {
            const label = document.createElement("label") as HTMLLabelElement;
            label.innerText = realm.description;
            label.style.gridColumn = `1`;
            realm_list.appendChild(label);

            const radio = document.createElement("input") as HTMLInputElement;
            radio.id = rm;
            radio.type = 'radio';
            radio.name = 'realm';
            radio.checked = rm == Realm.current;
            radio.style.gridColumn = `2`;
            realm_list.appendChild(radio);

            label.htmlFor = radio.id;

            radio.onchange = (event) => 
            {
                const location = viewerPositionDirectionZoom.fromRealm(rm);
                if (location && (event.target as HTMLInputElement).checked)
                {
                    css_hide(settings_dialog);
                    location.goto();
                }
            }
        })
    }
}

/** make a data object to prompt for hills */
function makeDataObjectforHillsList()
{
    const old_elem = document.getElementById("hills-data-list");
    if (old_elem && old_elem.parentNode)
        old_elem.parentNode.removeChild(old_elem);
    
    const fragment =  document.createDocumentFragment();
    const data_list = document.createElement('datalist')
    data_list.id= "hills-data-list";

    fragment.appendChild(data_list);

    HillsList.current().hills.forEach((hill)=>
        {
            const option = document.createElement("option");
            option.text = hill.name;
            data_list.appendChild(option);
        })
    document.body.append(fragment)
}