import { Color, Vector3 } from 'three';

import { ECEF, LatLong, LLA } from './coordinates.mjs'

import { srtmToMeshtake2, removeAllMeshes, getSetOfMeshesDisplayed, sg_statistics } from './camera.mjs'
import { toast } from './popups.mjs';

import { Realm } from './realm.mjs'
import { TileTake2, DemNameandTiles } from './resolution.mjs'
import { FeatureCodeList } from './feature.mjs';
import { VisualisationType } from './settings.mjs';
import { is_in_dist } from './installation.mjs';
import { inrange } from './html-util.mjs';

class DEMStore
{
    dems: DEM[] = [];

    push(dem: DEM)
    {
        console.assert(this.dems.find(d => dem.name == d.name) == null);

        this.dems.push(dem);
    }

    find(predicate : (dem: DEM) => DEM | undefined)
        { return this.dems.find(predicate); }

    findByName(name : string) 
    {
        return this.find((dem) => dem.name == name ? dem : undefined)
    }
}


export const g_dem_store  = new DEMStore();

class DEMLoader
{
    loading: DemNameandTiles | null;
    waiting: DemNameandTiles [];
    known_fails: Set<string>;
    timer_id: ReturnType<typeof setTimeout>;

    constructor()
    {
        this.loading = null;
        this.waiting = [];
        this.known_fails = new Set<string>();
        this.timer_id = setTimeout(() => this.processQueue(), 100);
    }

    processQueue()
    {
        if (this.loading == null)
        {
            const next = this.PopHeadfromWaiting();
            if (next)
                this.LoadandDisplayTile(next);
        }

        this.timer_id = setTimeout(() => this.processQueue(), 100);
    }

    Queue(dt: DemNameandTiles)
    {
        if ((!this.loading || this.loading.dem_name != dt.dem_name) && 
                 this.waiting.find(wdt => wdt.dem_name == dt.dem_name) == null)
        {
            this.waiting.push(dt);
        }
    }

    PopHeadfromWaiting() : DemNameandTiles | null
    {
        const [head, ...tail] = this.waiting;
        this.waiting = tail;
        return head;
    }

    SayLoadComplete(status: "ok" | "error")
    {
        if (status == "error" && this.loading)
            this.known_fails.add(this.loading.dem_name);

        this.loading = null;
    }

    LoadandDisplayTiles(dems_and_tiles_to_load : DemNameandTiles[])
    {
            dems_and_tiles_to_load.forEach(dt => this.Queue(dt));
    }

    LoadandDisplayTile(missing_dem: DemNameandTiles)
    {
        const bottom_left_corner = LatLong.fromLatLongString(missing_dem.dem_name);

        console.assert(!this.loading);

        this.loading = missing_dem;

        const p1 = FeatureCodeList.Loader(bottom_left_corner);

        const p2 = DEM.Loader(bottom_left_corner);

        Promise.allSettled([p1, p2])
            .then(([feature_result, dem_result]) => 
            {
                if (dem_result.status == "fulfilled" && feature_result.status == "fulfilled")
                {
                    parallelFoo(missing_dem.tiles, dem_result.value);

                    this.SayLoadComplete("ok");
                }
                else
                {
                    let reason: string;
                    if (dem_result.status == "rejected" && feature_result.status == "rejected")
                        reason = `${dem_result.reason}<BR>${feature_result.reason}`;
                    else if (dem_result.status == "rejected")
                        reason = dem_result.reason;
                    else if (feature_result.status == "rejected")
                         reason = feature_result.reason;
                    else    
                        reason = 'unknown'

                    throw new Error(reason);
                }
            })
            .catch(err => 
                { 
                    console.debug(err); 
                    toast(err);

                    this.SayLoadComplete("error");
                 });
    }

}
const g_dem_loader = new DEMLoader();


/**  Holds the heights for a tile of strm data */
export class DEM
{
    readonly bottom_left_corner : LatLong;
    readonly heights : Uint16Array;
    readonly arcsec : number;
    readonly nrow_cols : number;
    readonly name : string;

    /** Find the set of DEMs required for the tiles. If present, draw the tile: if absent, load and then draw */
    static updateFromRequiredtake2(dems_and_tiles: DemNameandTiles[])
    {
        const set_of_meshes_displayed = getSetOfMeshesDisplayed();

        const tiles_to_insert = new Set<string>();
        const required_tiles = new Set<string>();

        dems_and_tiles.forEach(dt =>
            dt.tiles.forEach(t =>
            {
                const tile_id = t.makeIdentifier();

                if (!set_of_meshes_displayed.has(tile_id))
                    tiles_to_insert.add(tile_id);

                required_tiles.add(tile_id);
            }))


        const meshes_to_remove = new Map(set_of_meshes_displayed);
        set_of_meshes_displayed.forEach((v, k) => { if (required_tiles.has(k)) meshes_to_remove.delete(k)});

        removeAllMeshes(meshes_to_remove);

        const dems_and_tiles_to_load: DemNameandTiles[] = [];

        sg_statistics.ntiles = tiles_to_insert.size;

        dems_and_tiles.forEach(dt =>
            {
                const dem = g_dem_store.findByName(dt.dem_name);
                if (dem)
                {
                    const tiles_to_make = dt.tiles.filter(tile => tiles_to_insert.has(tile.makeIdentifier()));

                    parallelFoo(tiles_to_make, dem);
                }
                else if (g_dem_loader.known_fails.has(dt.dem_name))
                {
/*                     console.debug(`loading skips known fail ${dt.dem_name}`)
 */                }
                else
                {
                    dems_and_tiles_to_load.push(dt);
                }
            })

        g_dem_loader.LoadandDisplayTiles(dems_and_tiles_to_load);
     }



    /** list the names & resolutions of srtms loaded */
    static dems_loaded() : { name: string}[]
    {
        return g_dem_store.dems.map(dem => ({name: dem.name }) );
    }

    /** grab a block of data from the server     */
    static Loader(bottom_left_corner : LatLong, test_pathname? : string) : Promise<DEM>
    {

        // https://dwtkns.com/srtm30m/
        console.assert(bottom_left_corner instanceof LatLong);

        const filename = test_pathname || this.pathnameForDEM(bottom_left_corner);

        return new Promise((resolve, reject) =>
        {

            fetch(filename)
                .then(response => 
                {
                    if (!response.ok)
                        throw new Error(`${response.statusText} ${response.url}`);
                    else
                        return response.arrayBuffer()
                })
                .then(array_buffer =>
                {
                    const heights = correctEndianBufferToHeights(array_buffer);

                    return heights;
                })
                .then(heights =>
                {
                    const dem = new DEM(bottom_left_corner, heights);

                    g_dem_store.push(dem);

                    resolve(dem);
                })

                .catch(err => 
                {
                    console.log(`${err}`);
                    reject(err.message);
                })
        }
        )
    }


    /** filename for strm data file */
    static pathnameForDEM(bottom_left_corner: LatLong) : string
    {
        // https://dwtkns.com/srtm30m/
        const latlong_name = bottom_left_corner.toLatLongString();

        const prefix = is_in_dist ? '..' : '.';

        const filename = `${prefix}/dem/dem1/${latlong_name}.hgt`

        return filename;
    }

    constructor(bottom_left_corner: LatLong, heights : Uint16Array)
    {
        const arcsec = 1;
        const nrow_cols = 3601;

        this.bottom_left_corner = bottom_left_corner;
        this.heights = heights;
        this.arcsec = arcsec;
        this.nrow_cols = nrow_cols;
        this.name = bottom_left_corner.toLatLongString();

        console.assert(heights.length == nrow_cols * nrow_cols);
    }

    makeIdentifier() : string
    {
        return this.name;
    }

    heightForRowColSignedMetres(row: number, column: number): number
    {
        let _return: number;
        
        const irow = Math.trunc(row);
        const icolumn = Math.trunc(column);
        if (irow == row && icolumn == column)
        {
            _return = this.heightForRowColSignedMetres1(irow, icolumn);
        }
        else
        {
            const irow_next = irow < this.nrow_cols - 1 ? irow + 1 : irow;
            const icolumn_next = icolumn < this.nrow_cols - 1 ? icolumn + 1 : icolumn;

            let height_sum = 0;
            let count = 0;
            if (irow != row)
            {
                if (icolumn != column)
                {
                    height_sum = this.heightForRowColSignedMetres1(irow, icolumn) +
                              this.heightForRowColSignedMetres1(irow, icolumn_next) +
                              this.heightForRowColSignedMetres1(irow_next, icolumn_next) +
                              this.heightForRowColSignedMetres1(irow_next, icolumn);
                    count = 4;
                }
                else
                {
                    height_sum = this.heightForRowColSignedMetres1(irow, icolumn) +
                              this.heightForRowColSignedMetres1(irow_next, icolumn);
                    count = 2;
                }
            }
            else
            {
                console.assert(icolumn != column);
                
                height_sum = this.heightForRowColSignedMetres1(irow, icolumn) +
                          this.heightForRowColSignedMetres1(irow, icolumn_next);
                count = 2;
            }
            
            console.assert(0 < count)
            _return = height_sum / count;
        }
        
        return _return;
    }

    heightForRowColSignedMetres1(row : number, column : number) : number
    {
        console.assert(row == Math.trunc(row) && column == Math.trunc(column));

        const unsigned_int16 = this.heights[row * this.nrow_cols + column];
        let signed_int16 : number;

        if (unsigned_int16 == 0x8000)
        {
            signed_int16 = 0;
        }
        else if (0x8000 < unsigned_int16 && unsigned_int16 <= 0xFFFF)
        {
            signed_int16 = 0xFFFF - unsigned_int16
            signed_int16 *= -1;
        }
        else
        {
            signed_int16 = unsigned_int16;
        }

        return signed_int16;
    }


    validRowCol(row : number, column : number) : boolean
    {
        function between(lo : number, value : number, high : number) { return lo <= value && value < high; }

        return between(0, row, this.nrow_cols) && between(0, column, this.nrow_cols)
    }

    squareForPoint(latlong : LatLong)
    {
        function between(lo : number, value : number, high : number) : boolean { return lo <= value && value <= high; }
        
        const lat_arcsec =  (latlong.latitude - this.bottom_left_corner.latitude) * 3600;
        const long_arcsec =  (latlong.longitude - this.bottom_left_corner.longitude) * 3600;

        if (between(0, lat_arcsec, 3600) && between(0, long_arcsec, 3600))
        {
            const column : number = Math.round(long_arcsec / this.arcsec);// A + 1;
            const row : number = Math.round((3600 - lat_arcsec) / this.arcsec);

            const height : number   = this.heightForRowColSignedMetres(row, column);

            return { success:true, column:column, row:row, height:height };
        }
        else
        {
            return { success: false };
        }
    }

    /** scan the cached srtm data objects for the lat-long position */
    static demForPoint(LLA : LLA) : DEM | null
    {
        const dems = g_dem_store.dems.filter(dem => dem.bottom_left_corner.latitude <= LLA.latitude && dem.bottom_left_corner.longitude <= LLA.longitude && 
                                                        LLA.latitude <= dem.bottom_left_corner.latitude + 1 && LLA.longitude <= dem.bottom_left_corner.longitude + 1)

        return dems.length ? dems[0] : null;
      }

    /** scan the cached srtm data objects for the lat-long position */
    static heightAtPoint(LLA : LLA) : number
    {
        const srtm = DEM.demForPoint(LLA);
        let _return = 0;

        if (srtm)
        {
            const sq_for_point = srtm.squareForPoint(LLA);
            _return = sq_for_point.height || 0; 
        }

        return _return;
    }

    /** get the colour data for a tile */
    getColourstake2(tile: TileTake2, feature_code_list: FeatureCodeList, material_type: VisualisationType): { colours: Float32Array, features: Int32Array }
    {
        const mark_id = tile.makeIdentifier();
        performance.mark(`getcolours ${mark_id}`)
    
        const src_nrows_cols = Math.trunc(this.nrow_cols / tile.dem_fraction);
        const dest_nrows_cols = 1 + (src_nrows_cols / tile.output_scale);

        const water_only = material_type == VisualisationType.Mat3D;
        const no_feature_is_white = material_type == VisualisationType.MatPhys;

        const colours = new Float32Array(dest_nrows_cols * dest_nrows_cols * 3);
        const features = new Int32Array(dest_nrows_cols * dest_nrows_cols)

        const blc_lon = this.bottom_left_corner.longitude;
        const blc_lat = this.bottom_left_corner.latitude; // bottom left corner lat/long

        let cn = 0;
        let fn = 0;

        /* colour the height scaled by this multiplier */
        const realmHeightMuliplier = Realm.getCurrentHeightMultiplier();

		for (let dest_row = 0; dest_row < dest_nrows_cols; dest_row++)
		{
			// const src_row = tile.lat_row * src_nrows_cols + dest_row * tile.output_scale;
            const src_row = (tile.dem_fraction - tile.lat_row - 1) * src_nrows_cols + dest_row * tile.output_scale;

			for (let dest_column = 0; dest_column < dest_nrows_cols; dest_column++)
			{
				const src_column = tile.long_col * src_nrows_cols + dest_column * tile.output_scale;
				
                const lat_arcsec = 3600 - (src_row * this.arcsec);  // arc sec is three or one
                const lng_arcsec = src_column * this.arcsec;
                const latitude = blc_lat + lat_arcsec / 3600;
                const longitude = blc_lon + lng_arcsec / 3600;

                const {isf: is_feature, code:code} = feature_code_list.setColourFromLatLong(latitude, longitude, water_only, colours, cn);

                if (!is_feature)
                {
                    if (no_feature_is_white)
                    {
                        colours[cn] = 0.75;
                        colours[cn + 1] = 0.75;
                        colours[cn + 2] = 0.75;
                    }
                    else
                    {
                        const total_metres = this.heightForRowColSignedMetres(src_row, src_column);
                        const height_km = total_metres * 0.001;
               
                        findColour4(height_km * realmHeightMuliplier, colours, cn);
                    }
                }

                features[fn] = Math.trunc(code);

                // fudgeColours(tile, colours, cn);
               
                cn += 3;
                fn += 1;
            }
		}

        performance.measure(`compute getcolours ${mark_id}`,`getcolours ${mark_id}`)

        return {colours: colours, features:features};
    }
}

function fudgeColours(tile: TileTake2, colours:Float32Array, cn: number)
{
    switch (tile.output_scale)
    {
        case 1: colours[cn] = 1; colours[cn + 1] = 0; colours[cn + 3] = 0; break;
        case 4: colours[cn] = 0; colours[cn + 1] = 1; colours[cn + 3] = 0; break;
        case 8: colours[cn] = 0; colours[cn + 1] = 0; colours[cn + 3] = 1; break;
        case 10: colours[cn] = 0.25; colours[cn + 1] = 0.75; colours[cn + 3] = 0; break;
        case 12: 
        default:colours[cn] = 0; colours[cn + 1] = 1; colours[cn + 3] = 1; break;
    }
}

export type verticesIndicesColours =
{
    vertices: Float32Array;
    indices:Uint32Array;
}

/** correct the endian - ness */
function correctEndianBufferToHeights(array_buffer : ArrayBuffer)
{
    const raw_heights = new Uint16Array(array_buffer);

    const uint16_array = new Uint16Array([0x1122]);
    const uint8_array  = new Uint8Array(uint16_array.buffer);

    // Android wrong endian
    if (uint8_array[0] == 0x22)
    {
    // dataview is the canonical way to do this .. but this 10x faster .. https://stackoverflow.com/questions/5320439
    // for loop faster than foreach    
           const n = raw_heights.length;
           for (let i = 0; i < n; i++)
           {
               let value = raw_heights[i];
               value = ((value & 0xFF) << 8) | ((value >> 8) & 0xFF);
               raw_heights[i] = value;
        }
    }

    return raw_heights;  
}



/** cpt-city | usgs */
const height_colours = 
[
    { h:0,    x:[127, 159, 101], r:0, g:0, b:0},
    { h:75,   x:[160, 194, 124], r:0, g:0, b:0},
    { h:150,  x:[185, 214, 124], r:0, g:0, b:0},
    { h:225,  x:[207, 224, 156], r:0, g:0, b:0},
    { h:300,  x:[223, 233, 168], r:0, g:0, b:0},
    { h:375,  x:[241, 238, 166], r:0, g:0, b:0},
    { h:450,  x:[237, 223, 159], r:0, g:0, b:0},
    { h:525,  x:[240, 210, 141], r:0, g:0, b:0},
    { h:600,  x:[230, 188, 138], r:0, g:0, b:0},
    { h:675,  x:[216, 165, 133], r:0, g:0, b:0},
    { h:750,  x:[197, 149, 135], r:0, g:0, b:0},
    { h:825,  x:[217, 165, 156], r:0, g:0, b:0},
    { h:900,  x:[227, 183, 177], r:0, g:0, b:0},
    { h:975,  x:[223, 192, 191], r:0, g:0, b:0},
    { h:1050, x:[239, 205, 217], r:0, g:0, b:0},
    { h:1125, x:[244, 215, 225], r:0, g:0, b:0},
    { h:1200, x:[243, 223, 233], r:0, g:0, b:0},
    { h:1275, x:[248, 227, 232], r:0, g:0, b:0},
    { h:1350, x:[248, 233, 238], r:0, g:0, b:0},
    { h:1425, x:[246, 240, 245], r:0, g:0, b:0}
]
	

let initialised_colours = false;
	
function initialiseColours()
{
    if (!initialised_colours)
    {
        height_colours.forEach(colour => 
        {
             const colour_linear = new Color(colour.x[0] / 255, colour.x[1] / 255,colour.x[2] / 255);

             // this is essential to prevent a washed out effect
            colour_linear.convertSRGBToLinear();    

            colour.r = colour_linear.r; 
            colour.g = colour_linear.g; 
            colour.b = colour_linear.b;
        })

        initialised_colours = true;
    }
}

function findColour4(height_km: number, colours: Float32Array, cn: number)
{
    if (!initialised_colours)
        initialiseColours();

    const height_metres = height_km * 1000;

    let j = 0;
    while (height_colours[j + 1].h <= height_metres && j < height_colours.length - 1)
        j++;

    colours[cn] = height_colours[j].r;
    colours[cn + 1] = height_colours[j].g;
    colours[cn + 2] = height_colours[j].b;
}

class ThreadPoolEntry
{
    readonly worker: Worker;
    readonly id:number;

    thread_work: ThreadWork | null;

    constructor(id: number)
    {
        this.worker = new Worker("js/compute_vertices.js");
        this.thread_work = null;
        this.id = id;

        this.worker.onmessage = (response) =>
        {
            const pool_entry = Threading.threading.thread_pool.find(pool_entry => pool_entry.worker == response.currentTarget);

            if (pool_entry && pool_entry.thread_work)
            {
                    const tile = pool_entry.thread_work.tile;
                    const dem  = pool_entry.thread_work.dem
                    pool_entry.thread_work = null;

                    const nrow_cols = Math.trunc(dem.nrow_cols / tile.dem_fraction);    // i.e. 10 -- see getTileSettake2
                    const scaled_nrow_cols = 1 + nrow_cols / tile.output_scale;

                    const results_buffer = response.data as ArrayBuffer
                    
                    const vertices_count = scaled_nrow_cols * scaled_nrow_cols * 3;
                    const indices_count = scaled_nrow_cols * scaled_nrow_cols * 6;
                    const vertices_size = vertices_count * Float32Array.BYTES_PER_ELEMENT;
                    const indices_size = indices_count * Uint32Array.BYTES_PER_ELEMENT;
                    
                    const calculated_vertices = new Float32Array(results_buffer, 0, vertices_count);
                    const calculated_indices = new Uint32Array(results_buffer, vertices_size, indices_count);
                    const calculated_normals = new Float32Array(results_buffer, vertices_size + indices_size, vertices_count);

                    Threading.threading.PulsePool();

                    srtmToMeshtake2(tile, dem, calculated_vertices, calculated_indices, calculated_normals);
            }
            else
            {
                if (pool_entry)
                    console.debug("No work found for thread return", id)
                else
                    console.debug("message from main received from worker:", 'thread not found');
            }
        }
        this.worker.onerror = (error) =>
            {
                console.debug('thread error', id, ' ', error.message);
            }
    }
}

class ThreadWork
{
    readonly tile: TileTake2;
    readonly dem: DEM;
    constructor(tile: TileTake2, dem: DEM) { this.tile = tile; this.dem = dem; }
}

class Threading
{
    readonly thread_pool: Array<ThreadPoolEntry>;
    readonly thread_work: Array<ThreadWork>;

    static  threading = new Threading();

    constructor()
    {
        this.thread_pool = new Array<ThreadPoolEntry>();
        this.thread_work = new Array<ThreadWork>();

        const max_workers = navigator.hardwareConcurrency || 4;

        for (let i = this.thread_pool.length; i < max_workers; i++)
            this.thread_pool.push(new ThreadPoolEntry(i))    
    }

    find(tile: TileTake2)
    {
        const sought = tile.makeIdentifier();
        const queued_already = this.thread_work.find(tw => tw.tile.makeIdentifier() == sought);
        const working_on = this.thread_pool.find(tpe => tpe.thread_work?.tile.makeIdentifier() == sought)

        return queued_already || working_on?.thread_work;
    }

    PulsePool()
    {
        let done = this.thread_work.length == 0;
        while (!done)
        {
            const thread_pool_entry = this.thread_pool.find(entry => entry.thread_work == null);
            if (thread_pool_entry)
            {
                const head = this.thread_work.splice(0, 1);
                if (head.length == 1)
                {
                    thread_pool_entry.thread_work = head[0];
                    const array_buffer = makeThreadParameter(thread_pool_entry.thread_work.tile, thread_pool_entry.thread_work.dem);

                    thread_pool_entry.worker.postMessage(array_buffer, [array_buffer]);
                }

                done = this.thread_work.length == 0;
            }
            else
            {
                done = true;
            }
        }
    }
    /// add the tile and its dem to the work queue, pulse the pool
    static QueueWork(tile: TileTake2, dem: DEM)
    {
        const queued_or_working_on_already = Threading.threading.find(tile);

        if (!queued_or_working_on_already)
        {
            Threading.threading.thread_work.push(new ThreadWork(tile, dem));

            Threading.threading.PulsePool();
        }
    }
}

/// Queue up some work on the threadpool
function parallelFoo(tiles: TileTake2[], dem: DEM)
{
    tiles.forEach(tile => { Threading.QueueWork(tile, dem)});
}

// pack the heights for the tile and the tile's parameters into an array buffer
function makeThreadParameter(tile: TileTake2, dem: DEM)
{
    const nrow_cols = Math.trunc(dem.nrow_cols / tile.dem_fraction);    // i.e. 10 -- see getTileSettake2
    const scaled_nrow_cols = 1 + nrow_cols / tile.output_scale; 

    const tna_size     = 4 * Float64Array.BYTES_PER_ELEMENT;
    const heights_size = scaled_nrow_cols * scaled_nrow_cols * Int16Array.BYTES_PER_ELEMENT;
    const padding      = 8 - (heights_size % 8);
    const ab_size      = heights_size + padding + tna_size;

    const array_buffer = new ArrayBuffer(ab_size);
    const heights = new Int16Array(array_buffer);

    const corners = tile.computeCornersandCentre();

    getHeightsforTile(tile, dem, scaled_nrow_cols, corners, heights);

    const tile_numbers = new Float64Array(array_buffer, heights_size + padding);

    tile_numbers[0] = corners[0].latitude;
    tile_numbers[1] = corners[0].longitude;
    tile_numbers[2] = tile.output_scale;
    tile_numbers[3] = scaled_nrow_cols;

    return array_buffer;
}

// take the heights for this tile from the dem's heights array
function getHeightsforTile(tile: TileTake2, dem: DEM, scaled_nrow_cols: number, corners: LatLong[], heights: Int16Array)
{
    console.assert(corners.map(corner => dem.squareForPoint(corner)).every( sq => sq.success));

    const squares = corners.map(corner => dem.squareForPoint(corner)).map((sq) => 
                    ({
                        row: sq.row == undefined ? 0 : sq.row,
                        column: sq.column == undefined ? 360: sq.column
                    }) );


    const blc = { row: squares[0].row, column: squares[0].column};
    const trc = { row: squares[2].row, column: squares[2].column};

    let hn = 0;
    for (let row = trc.row; row <= blc.row; row += tile.output_scale)
        for (let column = blc.column; column <= trc.column; column += tile.output_scale)
        {
            heights[hn] = dem.heightForRowColSignedMetres(row, column);
            hn++;
        }
}