import { parse } from "path";

export const inrange = (n:number, lo: number, hi: number) => !isNaN(n) && lo <= n && n <= hi;

/// Get element by id
export function byId(id:string) : HTMLElement | null
{
    const _rtn = document.getElementById(id);
    return _rtn;
}

///  hide div  with class css-shown
export function css_hide(div: HTMLElement)
{
    div.classList.remove('css-shown');
    div.classList.add('css-hidden');
}

///  show div  with class css-shown
export function css_show(div: HTMLElement)
{
    div.classList.remove('css-hidden');
    div.classList.add('css-shown');
}

/// div is shown if has class css-shown
export function css_is_shown(div: HTMLElement)
{
    return div.classList.contains("css-shown");
}

export function set_slider(name: string, value: number, conv: (n:number) => number, fmt: (n:number)=>string )
{
    const slider = byId(name) as HTMLInputElement;
    if (slider)
    {
        slider.value = conv(value).toFixed(0);
        const output = slider.parentNode?.children[2] as HTMLOutputElement;
        if (output)
            output.value = fmt(value);
    }
}

export type slider_params =
{
    n:string;
    min: number;
    max: number;
    step: number;
    on_inp:  (n:number) => void;
}

let debounce = 0;

/// set up a slider accordng to parameters
export function init_slider(sp: slider_params)
{
    const s = byId(sp.n) as HTMLInputElement;
    if (s) 
    {
        const oninput = (ev:Event) =>
            {
                if (ev.target)
                    {
                        const x = parseFloat((ev.target as HTMLInputElement).value);
                        window.clearTimeout(debounce);
                        debounce = window.setTimeout(() =>sp.on_inp(x), 200);
                    }

            };
        s.min = sp.min.toString();
        s.max = sp.max.toString();
        s.step = sp.step.toFixed(2);
        s.oninput = (ev:Event ) => oninput(ev);
    }

}