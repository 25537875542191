/** camera utilities */

import {
    Object3D,
    Box3,
    Vector3,
    Color,
    BufferAttribute,
    BufferGeometry,

    MeshLambertMaterial,
    MeshBasicMaterial,
    // MeshPhysicalMaterial,
    MeshStandardMaterial,
    
    ShaderMaterial,

    Mesh,
    PerspectiveCamera,
    Scene,
    WebGLRenderer,
    Material,
    Fog,
    Camera,

    Frustum,
    Matrix4
} from "three"

import { LLA, ECEF, Bearing, LatLong } from './coordinates.mjs'

import {  gLabeller } from './labeller.mjs'

import { positionMessage, toast, updateMaterialRadios } from './popups.mjs'

import { DEM, g_dem_store } from './dem.mjs'

import { gEventHandler, clamp } from './events.mjs'

import { makeLights, makeFog, generateBackgroundScene, updateSky } from './lights.mjs'

import { g_settings, VisualisationType } from './settings.mjs'
import { viewerPositionDirectionZoom } from "./viewposition.mjs"
import { FeatureCodeList } from "./feature.mjs"
import { TileNameAndResolution, TileTake2 } from "./resolution.mjs"

export const sg_statistics = { triangles: 0, ntiles: 0};

export const sg_sun_distance = 149600000;    // Earth orbits the sun at an average of 149.6 million km 149, 600, 000
export const sg_sun_diameter = 1391400;      // the Sun has a diameter of 1,391,400 km 

export class sceneCameraGlobals
{
    constructor(_renderer : WebGLRenderer, _scene: Scene, _camera : PerspectiveCamera, _meshes: Object3D, _sprites:Object3D)
    {
        this.renderer = _renderer;
        this.scene = _scene;
        this.camera = _camera;
        this.meshes = _meshes;
        this.sprites = _sprites;
        this.background_scene = null;
        this.background_camera = null;
    }
    renderer: WebGLRenderer;
    scene : Scene;
    camera: PerspectiveCamera;
    meshes: Object3D;
    sprites: Object3D;
    background_scene : Scene | null;
    background_camera: PerspectiveCamera | null;
}

export let g_scene_camera : sceneCameraGlobals;

let g_vertex_shader: string;
let g_fragment_shader: string;

/** create graphics context, camera, etc, load up the initial viewed data */
export function graphicsInitialise()
{
     const scene = new Scene();
    
    // Camera
    const camera = makeAddCamera(scene) ;

    const canvas = document.getElementById('canvas') as HTMLCanvasElement;

    // renderer
    try
    {
        loadShaders();

        const renderer = new WebGLRenderer({antialias: true, canvas: canvas});
        renderer.setClearColor("#233143");

        // renderer.outputColorSpace  = "srgb-linear";
    
        // Lights
        makeLights(scene);

        makeFog(scene);

        makeSceneCameraGlobals(renderer, scene, camera);
        
        generateBackgroundScene(renderer);
       
        setTimeout(() => renderLoop(renderer), 100);
    }
    catch (_error)
    {
        const err: Error = _error as Error;
        console.debug(err);
        toast(err.message);

    }
}

/** clear, then render background and foreground scenese */
function renderBackgroundandMainScenes(renderer: WebGLRenderer, scene:Scene, camera:Camera, background_scene : Scene | null, background_camera: Camera | null)
{
  renderer.autoClear = false;
  renderer.clear();

  if (background_camera && background_scene)
    renderer.render(background_scene, background_camera);
  renderer.render(scene, camera);
}

function renderLoop(renderer: WebGLRenderer)
{
    const rendering = function() {
        requestAnimationFrame(rendering);    // Constantly rotate box

        if (gEventHandler.isDirty())
        {
            renderBackgroundandMainScenes(renderer, g_scene_camera.scene, g_scene_camera.camera,  g_scene_camera.background_scene,  g_scene_camera.background_camera)
            gEventHandler.clearDirty();

            sg_statistics.triangles = renderer.info.render.triangles;

            const statistics = document.getElementById("statistics") as HTMLSpanElement;
            const triangles_s = sg_statistics.triangles.toLocaleString();
            statistics.innerHTML = `${triangles_s}<BR>${sg_statistics.ntiles}`
        }
    }

    rendering();
}



export function makeSceneCameraGlobals(renderer : WebGLRenderer, scene: Scene, camera : PerspectiveCamera )
{
    const meshes = new Object3D();
    const sprites = new Object3D();
    sprites.name = "sprites";

    scene.add(meshes);
    scene.add(sprites);


    g_scene_camera = new sceneCameraGlobals(renderer, scene, camera, meshes, sprites);
}

/** make a new camera and add it to the scene */
export function makeAddCamera(scene : Scene) : PerspectiveCamera
{
    const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.001, sg_sun_distance + sg_sun_diameter);// Renderer

    const ecef = ECEF.fromLLA3(54.45487168750526, -3.212127685546875, 0.978)
    camera.position.set(ecef.x, ecef.y, ecef.z);

    scene.add(camera);

    return camera;
}

function makeAlignmentVector(ecef_position : ECEF)
{
    const alignment_vector = new Vector3(ecef_position.x, ecef_position.y, ecef_position.z);
    alignment_vector.normalize();
    return alignment_vector.clone();
}

/** place the camera at an xyz position */
export function placeCamera(camera : PerspectiveCamera, ecef_position : ECEF, ecef_target : ECEF)
{
    camera.position.set(ecef_position.x, ecef_position.y, ecef_position.z);

    const alignment_vector = makeAlignmentVector(ecef_position);
    camera.up = alignment_vector;

    camera.lookAt(ecef_target.x, ecef_target.y, ecef_target.z);
}

/** camera points at bearing*/
export function lookBearing(look_bearing : Bearing)
{
    const camera = g_scene_camera.camera;

    const position = getCameraPosition(camera);

    const current_bearing = getCameraBearing(camera, position);

    let rotate_radians: number;
    if (look_bearing > current_bearing)
        rotate_radians = -1 * (look_bearing.radians - current_bearing.radians);
    else
        rotate_radians = current_bearing.radians - look_bearing.radians;

    camera.rotateY(rotate_radians);
}

/* zoom the camera */
export function zoomCamera(camera : PerspectiveCamera, zoom: number)
{
    zoom = clamp(zoom, 0.5, 20);
      
    camera.zoom = zoom /// assign new zoom value
    camera.updateProjectionMatrix(); /// make the changes take effect

    postCameraMove(camera);
}

/** get the camera's world position in xyz and LLA */
type cameraPosition =
{ 
    world:Vector3; 
    ecef: ECEF;
    lla: LLA
}

export function getCameraPosition(camera: PerspectiveCamera) : cameraPosition
{
    const world_position = new Vector3();
    camera.getWorldPosition(world_position);
  
    const ecef_position = new ECEF(world_position.x, world_position.y, world_position.z);
    const lla_position = LLA.fromECEF(ecef_position);
  
    return { world:world_position, ecef:ecef_position, lla:lla_position} ;
}

export function getCameraViewAngle()
{
    const position = getCameraPosition(g_scene_camera.camera);
    const alignment_vector = makeAlignmentVector(position.ecef);
    const direction = new Vector3();
    g_scene_camera.camera.getWorldDirection(direction);
    const angle = direction.angleTo(alignment_vector);

    return angle;
}

/** Clone the global camera, and point the clone at the horizon */
export function makeCameraCloneLookingatHorizon()
{
    const clone = g_scene_camera.camera.clone();

    const world_position = new Vector3();
    clone.getWorldPosition(world_position);
    world_position.normalize();

    const direction = new Vector3();
    clone.getWorldDirection(direction);
    
    const angle = direction.angleTo(world_position);

    const rotation =  angle - Math.PI / 2;
    clone.rotateX(rotation);

    return clone;
}


/// make a mesh for a tile and a DEM: might have calculated vertices passed in
export function srtmToMeshtake2(tile: TileTake2, dem : DEM, calculated_vertices: Float32Array | null, calculated_indices: Uint32Array | null, calculated_normals: Float32Array | null)
{
    let geometry: BufferGeometry;
    const mark_id = tile.makeIdentifier();
    performance.mark(`start ${mark_id}`)

    try
    {
        const material_type = g_settings.material_type;

        const feature_code_list = FeatureCodeList.findForDEM(dem.name);

        if (feature_code_list && calculated_vertices && calculated_indices && calculated_normals)
        {
             const {colours: dem_colours, features:dem_features} = dem.getColourstake2(tile, feature_code_list, material_type);

            geometry = new BufferGeometry();

            const positions = new BufferAttribute( calculated_vertices, 3 );
            const colours = new BufferAttribute( dem_colours, 3 ) ;
            geometry.setAttribute( 'position', positions );
            geometry.setAttribute( 'color',    colours);
    
            const features = new BufferAttribute(dem_features, 1);
            geometry.setAttribute('feature', features);

            const indices = new BufferAttribute(calculated_indices, 1);
            geometry.setIndex(indices);

            const normals = new BufferAttribute(calculated_normals, 3);
            geometry.setAttribute('normal', normals);

            makeMaterial(material_type, continuation);
        }
    }
    catch (error: unknown)
    {
        toast((error as Error).message);
        return;
    }

    function continuation(material: Material)
    {    
        const mesh = new Mesh( geometry, material );
        mesh.name = tile.makeIdentifier();

        console.assert(mesh.name == TileTake2.fromIdentifier(mesh.name)!.makeIdentifier());

        console.assert(g_scene_camera.meshes.children.find(m => m.name == mesh.name) == null);

        g_scene_camera.meshes.add(mesh);

        sg_statistics.ntiles --;

        makeLabelsAfterMakeMaterial();

        performance.measure(`compute ${mark_id}`,`start ${mark_id}` )
    }
    
}

/** make the labels, and mark scene dirty */
function makeLabelsAfterMakeMaterial()
{
    const position = getCameraPosition(g_scene_camera.camera);
    if (gLabeller)
    {
        gLabeller.updateLabels(position.ecef, position.lla);
    }
    
    gEventHandler.setDirty();
}

export function pointVisible(latlong: LatLong)
{
    const ecef = ECEF.fromLLA3(latlong.latitude, latlong.longitude,0);

    g_scene_camera.camera.updateMatrix(); // make sure camera's local matrix is updated
    g_scene_camera.camera.updateMatrixWorld(); // make sure camera's world matrix is updated

    const frustum = new Frustum();
    const matrix = new Matrix4().multiplyMatrices( g_scene_camera.camera.projectionMatrix, g_scene_camera.camera.matrixWorldInverse );
    frustum.setFromProjectionMatrix(matrix);

    const _vector = new Vector3(ecef.x, ecef.y, ecef.z);
    return frustum.containsPoint(_vector);

}

/** make a box-3 from the corners, check for visibility */
export function tileCornersVisible(corners: LatLong[])
{
    const box = new Box3();
    corners.forEach(corner => 
    {
        const ecef = ECEF.fromLLA3(corner.latitude, corner.longitude, 0);
        const v = new Vector3(ecef.x, ecef.y, ecef.z);
        box.expandByPoint(v);
    }
    );

    const frustum = new Frustum();
    const matrix = new Matrix4().multiplyMatrices(g_scene_camera.camera.projectionMatrix, g_scene_camera.camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(matrix);

    return frustum.intersectsBox(box);
}

/** compute vertex normals */
function CVN(index : Uint32Array, vertices: Float32Array, positionAttribute : BufferAttribute) : BufferAttribute
{
    const pA = new Vector3(), pB = new Vector3(), pC = new Vector3();
    // const pAx = new Vector3(), pBx = new Vector3(), pCx = new Vector3();
    const nA = new Vector3(), nB = new Vector3(), nC = new Vector3();
    const cb = new Vector3(), ab = new Vector3();

    const normals = new Float32Array( positionAttribute.count * 3 );
    const normalAttribute = new BufferAttribute(normals , 3 );

    const il = index.length;
    for ( let i = 0; i < il; i += 3 ) 
    {
        const vAtimes3 = index[i + 0] * 3;
        const vBtimes3 = index[i + 1] * 3;
		const vCtimes3 = index[i + 2] * 3;

        pA.fromArray(vertices, vAtimes3);
        pB.fromArray(vertices, vBtimes3);
        pC.fromArray(vertices, vCtimes3);

        cb.subVectors( pC, pB );
        ab.subVectors( pA, pB );
        cb.cross( ab );

        nA.fromArray( normals, vAtimes3);
        nB.fromArray( normals, vBtimes3);
        nC.fromArray( normals, vCtimes3);

        nA.add( cb );
        nB.add( cb );
        nC.add( cb );

        normals[vAtimes3 + 0] = nA.x;
        normals[vAtimes3 + 1] = nA.y;
        normals[vAtimes3 + 2] = nA.z;
        normals[vBtimes3 + 0] = nB.x;
        normals[vBtimes3 + 1] = nB.y;
        normals[vBtimes3 + 2] = nB.z;
        normals[vCtimes3 + 0] = nC.x;
        normals[vCtimes3 + 1] = nC.y;
        normals[vCtimes3 + 2] = nC.z;
    }

    const _vector = new Vector3();

    for ( let i = 0, il = normalAttribute.count; i < il; i ++ ) {

        _vector.fromBufferAttribute( normalAttribute, i );

        _vector.normalize();

        normalAttribute.setXYZ( i, _vector.x, _vector.y, _vector.z );
    }

    return normalAttribute;
}

export function remakeMaterials()
{
    const material_type = g_settings.material_type;
    g_scene_camera.meshes.children.forEach(
        mesh =>
        {
            function setMeshMaterial(material: Material): void { (mesh as Mesh).material = material; }

            const mesh_name = mesh.name;
            
            const tile = TileTake2.fromIdentifier(mesh_name);
            if (tile)
            {
                const geometry = (mesh as Mesh).geometry;


                makeMaterial(material_type, setMeshMaterial);

                const dem = g_dem_store.findByName(tile!.dem_name);

                if (dem)
                {
                    const ffind = (cl: FeatureCodeList) => cl.bottom_left_corner == dem.bottom_left_corner;
                    const feature_code_list = FeatureCodeList.feature_code_lists.find(ffind);
                    if (feature_code_list)
                    {
                        const { colours: dem_colours, features: dem_features } = dem.getColourstake2(tile, feature_code_list, material_type);
                        const colours = new BufferAttribute(dem_colours, 3);
                        geometry.setAttribute('color', colours);
                        geometry.attributes.color.needsUpdate = true;

                        const features = new BufferAttribute(dem_features, 1);
                        geometry.setAttribute('feature', features);
                    }
                }
            }
        });

        makeLabelsAfterMakeMaterial();
        
        updateMaterialRadios();
    }

/** remake the fog for the vistualisation update */    
export function remakeFog()
{
    makeFog(g_scene_camera.scene); 
    
    postCameraMoveNoAlign(g_scene_camera.camera);

    if (g_settings.material_type == VisualisationType.MatPano)
    {
        remakeMaterials();
    }

    gEventHandler.setDirty();
}


/** turn the visualisation type into a material */
function makeMaterial(material_type : VisualisationType, continuation : (material: Material) => void)
{
    let material : Material

    if (material_type == VisualisationType.MatPhys)
    {
        // roughness by trial-and-error: anything over 0.5 is OK
         material = new MeshStandardMaterial({vertexColors: true, roughness: 0.75, metalness: 0, wireframe: false }); 
    }
    else if (material_type == VisualisationType.Mat3D)
    {    
        // material = new MeshBasicMaterial( { vertexColors: true, wireframe: false } ); 
        material = new MeshLambertMaterial( { vertexColors: true, wireframe: false } ); 
    }
    else
    {
        const fog = g_scene_camera.scene.fog as Fog;
        const  is_water = FeatureCodeList.getIsWater();
        const uniforms = {
            fogColor:    { type: "c", value: fog.color },
            fogNear:     { type: "f", value: fog.near },
            fogFar:      { type: "f", value: fog.far },
            color1:      { value: new Color(0xF8F3A6)},
            color2:      { value: new Color(0x9470CE)},
            colors:      { value: distance_colors   },
            is_water:    { value: is_water }
          };
        
        material =   new ShaderMaterial({
            uniforms:     uniforms,
            vertexShader:  g_vertex_shader,
            fragmentShader:g_fragment_shader
          });
    }

    continuation(material);
}

function loadShaders()
{
   
    Promise.all(
        [ fetch('./js/shaders/vertexShader1.glsl'),
          fetch('./js/shaders/fragmentShader1.glsl')])
        .then(responses => 
        {
            return Promise.all(responses.map(response => response.text()))
        })
        .then(data => 
        {
           g_vertex_shader = data[0];
           g_fragment_shader = data[1];     
        })
        .catch(error => console.log(error)
        );
}


// temp take 2 remove all meshes
export function removeAllMeshes(meshes_to_remove?: Map<string, Mesh>)
{
    if (meshes_to_remove)
    {
        meshes_to_remove.forEach((mesh) =>
        {
            g_scene_camera.meshes.remove(mesh);
            mesh.geometry.dispose();
        })
    }
    else
    {
        const meshes = g_scene_camera.meshes.children.map(mesh => mesh as Mesh);
        meshes.forEach(mesh =>
        {
            g_scene_camera.meshes.remove(mesh);
            mesh.geometry.dispose();
        })
    }
}

/** Compute the set of tiles that are being displayed */
export function getSetOfMeshesDisplayed()
{
    const set_of_meshes_displayed = new Map<string, Mesh>();

    const meshes = g_scene_camera.meshes.children.map(mesh => mesh as Mesh);

    meshes.forEach(m => set_of_meshes_displayed.set(m.name, m));

    return set_of_meshes_displayed;
}

/** camera has moved -- prevent moving undergroumd, update the messages, draw the labels */
export function postCameraMove(camera : PerspectiveCamera)
{
    let position = getCameraPosition(camera);

    const altitude_metres = position.lla.altitude * 1000;
    const srtm_given_height = DEM.heightAtPoint(position.lla);

    if (altitude_metres < srtm_given_height)
    {
        const new_lla = new LLA(position.lla.latitude, position.lla.longitude, (srtm_given_height + 20) * 0.001);
        const new_ecef = ECEF.fromLLA(new_lla);

        camera.position.set(new_ecef.x, new_ecef.y, new_ecef.z);

        position = getCameraPosition(camera);
        
        gEventHandler.setDirty();
    }
    
    const alignment_vector = makeAlignmentVector(position.ecef);
    camera.up = alignment_vector;

// trying to align camera    -- should look at the horizon
    let direction = new Vector3();
    let target = new Vector3();

    camera.getWorldDirection(direction);
    direction = direction.multiplyScalar(1000);
    target = position.world;
    target.add(direction);

    camera.lookAt(target);

    computeShowPositionMessage(camera);

    if (gLabeller)
    {
        gLabeller.updateLabels(position.ecef, position.lla);
        updateSky();
    }

    g_settings.location = viewerPositionDirectionZoom.fromCamera(camera);

    updateTilesAfterCameraMove(position);
}

/**  just update the labels -- called where we look up and down */
export function postCameraMoveNoAlign(camera: PerspectiveCamera)
{
    const position = getCameraPosition(camera);

    if (gLabeller)
    {
        gLabeller.updateLabels(position.ecef, position.lla);
        updateSky();
    }

    computeShowPositionMessage(camera);

    updateTilesAfterCameraMove(position);
}

function computeShowPositionMessage(camera: PerspectiveCamera)
{
    const position = getCameraPosition(camera);

    const bearing = getCameraBearing(camera, position);
    const altitude_metres = position.lla.altitude * 1000;

    positionMessage(bearing,  altitude_metres, camera.zoom);
}

let debounce = 0;

/** Regenerate the set of tiles for a given position */
function updateTilesAfterCameraMove(position: cameraPosition)
{
    const fn = () => {
        const tile_set_take2 = TileNameAndResolution.getTileSettake2(position.lla, g_settings.fog, g_scene_camera.camera.zoom);

        DEM.updateFromRequiredtake2(tile_set_take2);
    };
    
    window.clearTimeout(debounce);
    debounce = window.setTimeout(fn, 200);
}

/** get the direction of view of a camera */
export function getCameraBearing(camera: PerspectiveCamera, position: cameraPosition) : Bearing
{
    let direction = new Vector3();
    let target = new Vector3();

    camera.getWorldDirection(direction);
    direction = direction.multiplyScalar(1000);
    target = position.world;
    target.add(direction);

    const lla_target = LLA.fromECEF(new ECEF(target.x, target.y, target.z));
    const bearing = position.lla.ComputeBearing(lla_target);

    return bearing;
}

const distance_colors : Color[] =
[
    new Color(0xaae9a1),
    new Color(0xb2dd8a),
    new Color(0x79c373),
    new Color(0x499d45),
    new Color(0x24B323),
    new Color(0x23B823),
    new Color(0xa1be5c),
    new Color(0x909f2e),
    new Color(0xA4A400),
    new Color(0x9e9c45),
    new Color(0xadaa67),
    new Color(0xbcb88a),
    new Color(0xc3bc7d),
    new Color(0xcbc070),
    new Color(0xdac957),
    new Color(0xe9d23d),
    new Color(0xf9db24),
    new Color(0xeac51b),
    new Color(0xdbaf12),
    new Color(0xcc9909),
    new Color(0xbe8400),
    new Color(0xc89c00),
    new Color(0xd2b500),
    new Color(0xdcce00),
    new Color(0xe7e700),
    new Color(0xd5cd1c),
    new Color(0xc3b438),
    new Color(0xbaa746),
    new Color(0xb19a54),
    new Color(0xa88d62),
    new Color(0x9f8170),
    new Color(0xa06b5e),
    new Color(0xa16055),
    new Color(0xa2554d),
    new Color(0xa33f3b),
    new Color(0xa43432),
    new Color(0xc35858),
    new Color(0xd26f6f),
    new Color(0xe18787),
    new Color(0xc96675),
    new Color(0xb14664),
    new Color(0x992552),
    new Color(0x810541),
    new Color(0x740746),
    new Color(0x670a4c),
    new Color(0x5a0c52),
    new Color(0x4d0f58),
    new Color(0x331464),
    new Color(0x2b307e),
    new Color(0x50609c),
    new Color(0x6378ab),
    new Color(0x7590b9),
    new Color(0x88a8c8),
    new Color(0x9ac0d7),
    new Color(0xadd8e6),
    new Color(0x9ec5e0),
    new Color(0x8fb3da),
    new Color(0x80a1d4),
    new Color(0x728fce),
    new Color(0x737dc4),
    new Color(0x756bba),
    new Color(0x7759b0),
    new Color(0x7a359d),
    new Color(0x7e1189),
    new Color(0x99399e),
    new Color(0xa656ad),
    new Color(0xb373bd),
    new Color(0xbf8fcc),
    new Color(0xccacdb),
    new Color(0xd9c9ea),
    new Color(0xe6e6fa),
    new Color(0xd3d3dd),
    new Color(0xc0c0c0),
new Color(0xdcdcdf)
];